<template>
  <fw-layout back-to="/" mobile-ready :loading="loading" :main-sidebar="false" loading-title="UC Competitions">
    <template #main-content>
      <div v-if="!loading && team && league" class="xl:max-w-screen-lg mx-auto px-2 md:px-5 my-5">
        <TeamHeader
          :team="team"
          :sport="sport"
          :edition="edition"
          :tournament="tournament"
          :league="league"
          @submit-team="submitTeam()"
        />
        <fw-panel :title="'Convite de participação'" boxed="lg" custom-class="bg-white relative">
          <fw-message class="mb-5"
            >Para aceitar o convite de participação na equipa, preencha ou verifique se os seus dados pessoais estão
            correctos</fw-message
          >
          <div>
            <PanelPersonalInformationForm
              ref="personalinfo"
              :edition-key="edition.key"
              :league="league"
              :tournament-key="tournamentKey"
              :tournament="tournament"
              :boxed="false"
              :check-terms="true"
              @saved="answerInvitation(true)"
            ></PanelPersonalInformationForm>

            <div class="flex mt-5">
              <fw-button
                class="ml-auto"
                :disabled="saving"
                :loading="saving"
                type="link-danger"
                @click.native="answerInvitation(false)"
                >Recusar convite</fw-button
              >
              <fw-button
                type="primary"
                class="ml-2"
                :disabled="saving"
                :loading="saving"
                @click.native="$refs['personalinfo'].saveUserData()"
                >Guardar e aceitar convite</fw-button
              >
            </div>
          </div>
        </fw-panel>
      </div>
      <div v-else-if="!loading" class="py-5 text-center text-gray-500">Equipa ou convite não encontrados</div>

      <fw-panel-info debug label="Data (raw)" class="my-4">
        <json-viewer :value="{ edition, league, tournament, sport, team, acceptTerms }"></json-viewer>
      </fw-panel-info>
    </template>
  </fw-layout>
</template>
<script>
import PanelPersonalInformationForm from '@/components/panels/PanelPersonalInformationForm'
import TeamHeader from '@/components/header/TeamHeader.vue'

import { GENDERS } from '@/utils/index.js'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'

export default {
  components: { PanelPersonalInformationForm, TeamHeader },
  data() {
    return {
      loading: true,
      saving: false,
      team: null,
      tournament: null,
      league: null,
      edition: null,
      sport: null,
      validations: {},
      step: 'personal_info',
      acceptTerms: false
    }
  },
  computed: {
    user() {
      return this.$store.getters.getUser
    },

    api() {
      return this.$store.state.api.base
    },

    tournamentKey() {
      return this.$route.params.tournamentKey
    },

    teamKey() {
      return this.$route.params.teamKey
    },

    invitationKey() {
      return this.$route.params.inviteKey
    },

    language() {
      return this.$store.state.language || 'pt'
    }
  },
  mounted() {
    this.getTeam()
    this.getTournament()
  },

  methods: {
    async answerInvitation(accept = true) {
      this.$buefy.dialog.confirm({
        type: accept ? 'is-success' : 'is-danger',
        title: accept ? 'Aceitar convite' : 'Recusar convite',
        message: accept
          ? 'Tem a certeza que pretende aceitar o convite? Atenção que não poderá participar noutra equipa dentro da mesma modalidade.' // TODO: explain invalidated
          : 'Tem a certeza que pretende recusar o convite?',
        onConfirm: async () => {
          try {
            console.log('accept invitation :>> ')
            const response = await this.api.acceptTeamInvitation(this.teamKey, this.invitationKey, accept)
            console.log('accept invitation :>> ', response)
            if (accept) {
              this.$router.push({
                name: 'team-view',
                params: {
                  tournamentKey: this.tournamentKey,
                  teamKey: this.teamKey
                }
              })
            } else {
              this.$router.push({ name: 'home' })
            }
          } catch (error) {
            const genderError = utils.errors(error).getField('gender')
            if (genderError) {
              this.$buefy.snackbar.open({
                message: `Este torneio apenas permite participantes do género ${
                  GENDERS[this.tournament.gender][this.language]
                }`,
                type: 'is-warning',
                position: 'is-top-right',
                indefinite: true,
                duration: 2000,
                queue: false
              })
            }
          }
          this.saving = false
        },
        confirmText: accept ? 'Aceitar convite' : 'Rejeitar convite',
        cancelText: 'Cancelar'
      })
    },

    async getTeam() {
      utils.tryAndCatch(
        this,
        async () => {
          this.loading = true
          const response = await this.api.getTeam(this.teamKey)
          console.log('getTeam :>> ', response)
          this.team = response.team
          this.validations = response.validations
        },
        () => {
          setTimeout(() => {
            this.loading = false
          }, 500)
        }
      )
    },

    async getTournament() {
      utils.tryAndCatch(this, async () => {
        const response = await this.api.getTournament(this.tournamentKey)
        console.log('getTournament :>> ', response)
        this.tournament = response.tournament
        this.getLeagueDetails()
      })
    },

    async getLeagueDetails() {
      utils.tryAndCatch(this, async () => {
        const response = await this.api.getLeagueDetails(this.tournament.edition_key, this.tournament.league_key)
        console.log('getLeagueDetails :>> ', response)
        this.edition = response.edition
        this.league = response.league
        this.sport = response.league.sports.find(el => el.key == this.tournament.sport_key)
      })
    }
  }
}
</script>
